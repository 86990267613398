import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../config/axios.config'

const initialState = {
    isLoading: false,
    error: null,
    data: null
}

export const getCropHealthData = createAsyncThunk(
    'crop-health',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('policy_makers/crop-health', payload);
            return res.data;
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during creating user');
            }
        }
    }
)

const cropDataSlice = createSlice({
    name: 'cropData',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCropHealthData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCropHealthData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload.data
            })
            .addCase(getCropHealthData.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message
            });
    }
})

export default cropDataSlice.reducer