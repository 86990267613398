import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from '../../config/axios.config';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
    message: null
}

export const createAgriculturalSeason = createAsyncThunk(
    'create-season',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('seasons/create', payload);
            return res.data; 
        } catch (error) {
            console.error(`Error creating season: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while creating season');
            }
        }
    }
)

export const getAllAgriculturalSeasons = createAsyncThunk(
    'get-all-agricultural-seasons',
    async(payload, { rejectWithValue }) => {
        try {
            const year = new Date().getFullYear();
            const res = await axiosInstance.get(`seasons/year_seasons?year=${year}`);
            return res?.data?.data;  
        } catch (error) {
            console.error(`Error getting all seasons: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting all seasons');
            }
        }
    }
)

export const getCurrentAgriculturalSeason = createAsyncThunk(
    'get-current-agricultural-season',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('seasons/current');
            return res?.data?.data;  
        } catch (error) {
            console.error(`Error getting current season: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting current season');
            }
        }
    }
)

export const deleteAgriculturalSeason = createAsyncThunk(
    'delete-agricultural-season',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.delete(`seasons/delete?id=${payload.id}`);
            return res?.data?.data;  
        } catch (error) {
            console.error(`Error deleting season: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while deleting season');
            }
        }
    }
)

const agriculturalSeasonSlice = createSlice({
    name: 'crops',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(createAgriculturalSeason.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createAgriculturalSeason.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.message = payload?.message;
            })
            .addCase(createAgriculturalSeason.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            .addCase(getAllAgriculturalSeasons.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllAgriculturalSeasons.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
                state.message = payload?.message;
            })
            .addCase(getAllAgriculturalSeasons.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            .addCase(getCurrentAgriculturalSeason.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCurrentAgriculturalSeason.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
                state.message = payload?.message;
            })
            .addCase(getCurrentAgriculturalSeason.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            .addCase(deleteAgriculturalSeason.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteAgriculturalSeason.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.message = payload?.message;
            })
            .addCase(deleteAgriculturalSeason.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
    }
})

export default agriculturalSeasonSlice.reducer;