import { TrashIcon } from "@heroicons/react/24/outline";
import PropTypes from 'prop-types';

const CropsTable = ({ crops, deleteCrop }) => {
  const handleDelete = ({ id }) => {
    deleteCrop(id);
  }
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <tbody>
        {crops &&
          crops.map((crop) => (
            <tr key={crop.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="flex flex-col px-6 py-4 gap-1x text-gray-900 whitespace-nowrap dark:text-white">
                <h4 className="font-semibold">{crop.name}</h4>
              </td>
              <td className="px-6 py-4">{crop.description} </td>
              <td className="px-6 py-4">{crop.time_to_yield} days</td>
              <td className="px-6 py-4 text-right">
                <TrashIcon
                  className="w-4 h-4 hover:text-red-600"
                  role="button"
                  onClick={() => handleDelete(crop)}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

CropsTable.propTypes = {
  crops: PropTypes.array.isRequired, 
  deleteCrop: PropTypes.func.isRequired
}

export default CropsTable;
