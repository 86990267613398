import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../features/auth/authSlice";
import Joi from "joi";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const isLoading = useSelector((state) => state.auth.isLoading);
  const authError = useSelector((state) => state.auth.error);

  // Joi Validation schema
  const loginSchema = Joi.object({
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'rw'] }}).required(),
    password: Joi.string().required(),
  });

  const handleLogin = useCallback(async (event) => {
    event.preventDefault();

    const credentials = {
      email,
      password
    }

    try {
      await loginSchema.validateAsync(credentials, { abortEarly: false });
      setErrors({});
      dispatch(signIn(credentials));
    } catch (err) {
      const errors_ = {}
      err.details.forEach((err) => {
        errors_[err.path[0]] = err.message;
      });
      setErrors(errors_);
    }
  }, [dispatch, email, loginSchema, password]);

  useEffect(() => {
    if (authError)
    {
      toast.error(authError, {
        theme: 'colored',
        position: 'top-right',
      });
    }
  }, [authError]);

  if (localStorage.getItem('token')) {
    window.location.replace('/');
    return null;
  }

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-8 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h1 className="text-3xl font-bold leading-9 tracking-tight text-indigo-600">
            Smart Agriculture
          </h1>
          <h2 className="mt-10 font-bold leading-9 tracking-tight text-gray-900 text-md">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
                >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                {errors.email && <span className="text-red-500">{errors.email}</span>}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                  >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/forget-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                {errors.password && <span>{errors.password}</span>}
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={handleLogin}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                {isLoading ? 'Logging in' : 'Sign in'}
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
