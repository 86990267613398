import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from '../../config/axios.config';

const initialState = {
    isLoading: false,
    data: null,
    error: null
}

const getUsers = createAsyncThunk(
    'get-users',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('auth/users', payload);
            return res?.data?.data; 
        } catch (error) {
            console.error(`Error getting system users: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting users data');
            }
        }
    }
)

const usersReducer = createSlice({
    name: 'users-reducer',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUsers.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
            })
            .addCase(getUsers.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error;
            })
    }
});

export default usersReducer.reducer;