import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import cropHealthDataReducer from '../features/crop-health/cropHealthSlice';
import cropLandDataReducer from '../features/crop-land/cropLandSlice';
import satelliteDatesReducer from '../features/satellite-dates/satelliteDatesSlice';
import AdministrativeBoundaryReducer from '../features/administrative-boundary/boundariesSlice';
import CIPCropsSlice from '../features/crops/cropsSlice';
import agriculturalSeasonSlice from '../features/seasons/agriculturalSeasonSlice';
import usersSlice from '../features/users/users.slice';
import exportSlice from "../features/MapExport/ExportSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cropHealth: cropHealthDataReducer,
    cropLand: cropLandDataReducer,
    satelliteDates: satelliteDatesReducer,
    administrativeBoundary: AdministrativeBoundaryReducer,
    crops: CIPCropsSlice,
    seasons: agriculturalSeasonSlice,
    users: usersSlice,
    export : exportSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware( {
    serializableCheck: false
  }),
});
