import { createContext, useContext, useMemo } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    const contextValue = useMemo(() => ({
        token,
        user
    }), [token, user]);
    return <AuthContext.Provider value={ contextValue }>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider