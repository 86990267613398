import { XMarkIcon } from "@heroicons/react/24/outline";
import Joi from "joi";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { registerUser } from "../../../features/auth/authSlice";
import PropTypes from 'prop-types';

const AddUserModal = ({ showModal, toggleModal, roles }) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.auth.message);
  const error = useSelector((state) => state.auth.error);
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [errors, setErrors] = useState({});


  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const registerSchema = Joi.object({
    fname: Joi.string().required(),
    lname: Joi.string().required(),
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'rw'] }}).required(),
    role: Joi.string().valid('policy_maker', 'large_hold_farmer').required(),
  });

  const handleRegister = useCallback(async (event) => {
    event.preventDefault();
    const values = {
      fname,
      lname,
      email,
      role: selectedRole,
    }

    try {
      await registerSchema.validateAsync(values, { abortEarly: false });
      const payload = {
        name: `${fname} ${lname}`,
        email,
        username: email,
        role: selectedRole
      }
      dispatch(registerUser(payload));
      setErrors({});
    } catch (err) {
      const errors_ = {}
      err.details.forEach((err) => {
        errors_[err.path[0]] = err.message;
      });
      console.log(errors_);
      setErrors(errors_);
    }
  }, [fname, lname, email, selectedRole, registerSchema, dispatch]);

  
  useEffect(() => {
    if (message != null) {
      toast.success(message, {
        theme: 'colored',
        position: 'top-right',
      });
    }
  }, [message]);

  useEffect(() => {
    if (error != null) {
      toast.error(error, {
        theme: 'colored',
        position: 'top-right',
      });
    }
  }, [error]);

  return (
    showModal &&
    createPortal(
      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full backdrop-blur-sm"
      >
        <div className="relative w-full max-w-md">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-900">
            <div className="flex items-start justify-between p-2 border-b rounded-t dark:border-gray-00 bg-gray-600 px-6 lg:px-8">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
                Invite new user.
              </h4>
              <XMarkIcon className="w-6 h-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-" role="button" onClick={toggleModal} />
            </div>
            <div className="px-6 py-6 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-white"
                      >
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="fname"
                          id="fname"
                          autoComplete="fname"
                          onChange={(e) => setFname(e.target.value)}
                          placeholder="John"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {errors.fname && (
                        <span className="text-red-500">{errors.fname}</span>
                      )}
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium leading-6 text-white"
                      >
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="lname"
                          id="lname"
                          autoComplete="lname"
                          onChange={(e) => setLname(e.target.value)}
                          placeholder="Doe"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {errors.lname && (
                        <span className="text-red-500">{errors.lname}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email@example.com"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.email && (
                        <span className="text-red-500">{errors.email}</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        value={email}
                        disabled={true}
                        placeholder="email@example.com"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.username && (
                        <span className="text-red-500">{errors.username}</span>
                      )}
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Role
                    </label>
                    {roles.map((role) => (
                      <div key={role.id} className="flex py-2">
                        <div className="flex items-center h-5">
                          <input
                            id={`role-${role.id}`}
                            type="radio"
                            value={role.name}
                            name="selectedRole"
                            checked={role.name === selectedRole}
                            onChange={handleRoleChange}
                            className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-800 focus:bg-blue-800 dark:focus:ring-blue-600 dark:ring-offset-blue-800 focus:ring-2 dark:bg-white"
                          />
                        </div>
                        <div className="ml-2 text-sm">
                          <label
                            htmlFor={`role-${role.id}`}
                            className="font-medium text-gray-900 dark:text-gray-300"
                          >
                            {role.display_name}
                          </label>
                          <p className="text-xs font-normal text-gray-500 dark:text-gray-300">
                            {role.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {errors.selectedRole && (
                        <span className="text-red-500">{errors.selectedRole}</span>
                      )}
                </form>
              </div>
              <ToastContainer />
            </div>
            <div class="flex items-center px-6 py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 justify-end bg-gray-600">
              <button
                onClick={handleRegister}
                data-modal-hide="staticModal"
                type="button"
                class="flex justify-center rounded-md bg-indigo-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Register
              </button>
              <button
                data-modal-hide="staticModal"
                type="button"
                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    )
  );
};

AddUserModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
}

export default AddUserModal;
