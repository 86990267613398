import { XMarkIcon } from "@heroicons/react/24/outline";
import Joi from "joi";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { createCIPCrop } from "../../../features/crops/cropsSlice";
import PropTypes from 'prop-types';

const AddCropModal = ({ showModal, toggleModal }) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.crops.message);
  const error = useSelector((state) => state.crops.error);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [timeToYield, setTimeToYield] = useState("");
  const [season, setSeason] = useState("");
  const [errors, setErrors] = useState({});

  const addCropSchema = Joi.object({
    name: Joi.string().required(),
    description: Joi.string().required(),
    timeToYield: Joi.required(),
    season: Joi.required(),
  });

  const handleAddCrop = useCallback(async (event) => {
    event.preventDefault();
    const values = {
      name,
      description,
      timeToYield,
      season
    }

    try {
      await addCropSchema.validateAsync(values, { abortEarly: false });
      const payload = {
        name,
        description,
        time_to_yield: timeToYield,
        season
      }
      dispatch(createCIPCrop(payload));
      setErrors({});
    } catch (err) {
      const errors_ = {}
      err.details.forEach((err) => {
        errors_[err.path[0]] = err.message;
      });
      console.log(errors_);
      setErrors(errors_);
    }
  }, [name, description, timeToYield, season, addCropSchema, dispatch]);

  
  useEffect(() => {
    if (message != null) {
      toast.success(message, {
        theme: 'colored',
        position: 'top-right',
      });
    }
  }, [message]);

  useEffect(() => {
    if (error != null) {
      toast.error(error, {
        theme: 'colored',
        position: 'top-right',
      });
    }
  }, [error]);

  return (
    showModal &&
    createPortal(
      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full backdrop-blur-sm"
      >
        <div className="relative w-full max-w-md">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-900">
            <div className="flex items-start justify-between p-2 border-b rounded-t dark:border-gray-00 bg-gray-600 px-6 lg:px-8">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
                Add CIP Crop.
              </h4>
              <XMarkIcon className="w-6 h-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-" role="button" onClick={toggleModal} />
            </div>
            <div className="px-6 py-6 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        type="name"
                        autoComplete="name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Maize"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.name && (
                        <span className="text-red-500">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Description
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="description"
                        name="description"
                        type="description"
                        autoComplete="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="A small description of the crop."
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      > </textarea>
                      {errors.description && (
                        <span className="text-red-500">{errors.description}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Time Needed To Yield
                    </label>
                    <div className="mt-2">
                      <input
                        id="timeToYield"
                        name="timeToYield"
                        type="text"
                        autoComplete="timeToYield"
                        onChange={(e) => setTimeToYield(e.target.value)}
                        placeholder="60"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.timeToYield && (
                        <span className="text-red-500">{errors.timeToYield}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Season
                    </label>
                    <div className="mt-2">
                      <select
                        id="season"
                        name="season"
                        onChange={(e) => setSeason(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-4 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="A">Season A</option>
                        <option value="B">Season B</option>
                        <option value="C">Season C</option>
                      </select>
                      {errors.name && (
                        <span className="text-red-500">{errors.name}</span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <ToastContainer />
            </div>
            <div class="flex items-center px-6 py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 justify-end bg-gray-600">
              <button
                onClick={handleAddCrop}
                data-modal-hide="staticModal"
                type="button"
                class="flex justify-center rounded-md bg-indigo-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add
              </button>
              <button
                data-modal-hide="staticModal"
                type="button"
                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    )
  );
};

AddCropModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
}

export default AddCropModal;
