import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from '../../config/axios.config';

const initialState = {
    isLoading: false,
    data: null,
    error: null
}

export const getCropLandData = createAsyncThunk(
    'crop-land',
    async (payload, { rejectWithValue }) => {
        try {
           const res = await axiosInstance.post('policy_makers/crop-land', payload);
           return res.data.data; 
        } catch (error) {
            console.error(`Error getting crop land data: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting crop land data');
            }
        }
    }
)

const cropLandSlice = createSlice({
    name: 'crop-land',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCropLandData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCropLandData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
            })
            .addCase(getCropLandData.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
    }
});

export default cropLandSlice.reducer;