import React from 'react'
import AppLayout from '../../layouts/AppLayout'

const Reports = () => {
  return (
    <AppLayout>
        
    </AppLayout>
  )
}

export default Reports