import { Navigate, Outlet } from "react-router";
import { useAuth } from "../providers/authProvider";

export const ProtectedRoute = ({ forRole = '' }) => {

    const { token, user } = useAuth();

    if (!token || !user) {
        return <Navigate to="/login" />
    }

    if (forRole && user.role !== forRole) {
        return <Navigate to="/" />
    }

    return <Outlet />
};