import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/images/icons/satelite_icon.png';
import {format } from 'date-fns';

const exportMap = ({ text, aoi, selectedIndex, viewRef, chartContainer, cropsChartContainer, pieChartContainer, pieChartStatistics, legend, user }) => {
    try {
        const view = viewRef;
        if (view) {
            view.takeScreenshot({ format: 'png' }).then(function (screenshotData) {
                let doc = new jsPDF({
                    orientation: 'landscape'
                });

                const mapDescriptionText = "The map represents cultivated vs non-cultivated cropland estimated by utilizing time series data from the Sentinel satellite and applying a specific threshold of \nNDVI values ranging from 0.05 to 0.15. These NDVI values correspond to areas characterized as either bare land or ploughed land, primarily during the ploughing \nand sowing period, which occurs between August 2, 2023, and September 29, 2023. Additionally, the determination of the NDVI threshold in the range of 0.05 to \n0.15 for ploughed land is based on an analysis conducted on farms with confirmed UPIS (Unique Parcel Identification System) located within the \nKatabagemu Sector of Nyagatare District."
                const statsDescriptionText = `And the statistics above communicate the estimated cropland, the cultivated percentage of that identified cropland and the total area in hectares for ${aoi}.`
                const cropCalendarDescriptionText = "This feature generates a crop calendar for various crops based on the current date, taking into account the growth and harvest periods of the various crops. \nThe functionality permits the addition of new crops, but currently, it is tailored to the national Crop Intensification Program (CIP)crops (Maize, wheat, rice, \nwhite potato, beans and cassava)."
                const indicesDescriptionText = "Max - the maximum value of an index \nMean - the average value of an index \nMin - the minimum value of an index  \nStandard Deviation - is a measure of the amount of variation or dispersion of a set of values.  A low standard deviation indicates that the values tend to be \nclose to the mean (also called the expected value) of the set, while a high standard deviation indicates that the values are spread out over a wider range."
                
                const indicesDescriptions = {
                    ndvi: "NDVI (Normalized Difference Vegetation Index): \n\nNDVI is a widely used index to assess the greenness and health of vegetation. It measures the difference between \nthe reflectance of near-infrared and red light. Higher NDVI values typically indicate healthier and more \nabundant vegetation, while lower values can signify barren or non-vegetated areas. \nRange: -1 to +1 \nHealthy vegetation: 0.2 to 0.8 \nBarren areas: < 0.1 \nWater bodies: < 0",
                    ndmi: "NDMI (Normalized Difference Moisture Index): \n\nNDMI focuses on moisture content in vegetation. It calculates the difference between near-infrared and short-wave infrared \nreflectance. Higher NDMI values often suggest wetter and more hydrated vegetation, while lower values may indicate \ndrier or stressed vegetation. \nRange: -1 to +1 \nHigh moisture: Positive values (usually > 0) \nDry areas: Negative values (usually < 0)",
                    reci: "RECI (Red Edge Chlorophyll Index): \n\nThe RECI index is used to estimate the chlorophyll content in plants. It relies on the red-edge region of the electromagnetic \nspectrum. Higher RECI values generally indicate greater chlorophyll concentration, which is associated with healthier \nand more productive vegetation. \nRange: Typically, 0 to 1 \nHigher values indicate higher chlorophyll content in plants.",
                    evi: "EVI (Enhanced Vegetation Index): \n\nEVI is an improved version of NDVI that corrects for some atmospheric and background effects. It's particularly useful for \nmonitoring changes in vegetation cover and health. Higher EVI values correspond to denser and healthier vegetation. \nRange: -1 to +1 \nHealthy vegetation: Positive values (usually > 0) \nBarren areas: Negative values (usually < 0)",
                    ndre: "NDRE (Normalized Difference Red Edge): \n\nNDRE is another index used to assess vegetation health. It focuses on the red edge region of the spectrum, \nwhich is sensitive to chlorophyll content. Higher NDRE values suggest healthier and more chlorophyll-rich vegetation. \nRange: -1 to +1 \nSimilar to NDVI, with values indicating vegetation health.",
                    msavi: "MSAVI (Modified Soil-Adjusted Vegetation Index): \n\nMSAVI is designed to minimize the influence of soil backgrounds in vegetation assessments. It's beneficial \nfor areas with exposed soil. Higher MSAVI values indicate more robust vegetation, while lower values may \nsignify a mixture of soil and sparse vegetation. \nRange: -1 to +1 \nHealthy vegetation: Positive values (usually > 0) \nAreas with exposed soil: Negative values (usually < 0)",
                }
                
                let indexDescriptionText;
                switch (selectedIndex) {
                    case "ndvi":
                        indexDescriptionText = indicesDescriptions.ndvi;
                        break;
                    case "ndmi":
                        indexDescriptionText = indicesDescriptions.ndmi;
                        break;
                    case "reci":
                        indexDescriptionText = indicesDescriptions.reci;
                        break;
                    case "evi":
                        indexDescriptionText = indicesDescriptions.evi;
                        break;
                    case "ndre":
                        indexDescriptionText = indicesDescriptions.ndre;
                        break;
                    case "msavi":
                        indexDescriptionText = indicesDescriptions.msavi;
                        break;
                    default:
                        indexDescriptionText = '';
                        break;
                }
                
                // PAGE DEFINITIONS
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();

                // MAP CONSTANTS
                const mapTitle = `Area of interest: ${aoi}`;
                const mapHeight = 100;
                const mapWidth = (mapHeight * 1.65);

                // PIE CHART CONSTANTS
                const pieChartTitle = 'Summmary'
                const pieChartHeight = 50;
                const pieChartWidth = pieChartHeight;

                // CHART CONSTANTS
                const chartTitle = 'Indices'
                const chartWidth = pageWidth * 0.85;
                const chartHeight = chartWidth / 3.3;

                // CROPS CHART CONSTANTS
                const cropsChartTitle = 'CIP Crops Calendar'
                const cropsChartWidth = pageWidth * 0.85;
                const cropsChartHeight = cropsChartWidth / 3.3;

                // PAGE HEADER
                doc.setFontSize(16);
                doc.rect(0, 0, pageWidth, 20, 'F');
                doc.addImage(logo, logo.slice(-3), 20, 5, 10, 10);
                doc.setTextColor(0);
                doc.setFontSize(20);
                doc.setFont('times', 'bold');
                doc.setTextColor(0, 157, 215)
                doc.text(`${text.toUpperCase()}`, (pageWidth - doc.getTextWidth(text)) / 2, 14);
                doc.setFontSize(14);
                doc.text(`${format(new Date(), 'PPP')}`, pageWidth - 50, 12);
                doc.setTextColor(0);
                doc.setFontSize(10);

                doc.setFontSize(25);
                doc.setFont('times', 'bold');

                // STARTING Y FOR CONTENT
                let currentY = 30;

                // FUNCTION TO ADD IMAGE CONTENT TO PDF
                const addContent = (content, height, width, imageType) => {
                    if (currentY + height > pageHeight) {
                        doc.addPage();
                        currentY = 15;
                    }

                    doc.addImage(
                        content,
                        imageType,
                        20,
                        currentY,
                        width,
                        height
                    );

                    currentY += height + 10;
                };

                // ADDING MAP CONTENT
                doc.setFontSize(16);
                doc.setTextColor(0, 157, 215);
                doc.text(mapTitle, 20, currentY);
                currentY += 5;
                addContent(screenshotData.dataUrl, mapHeight, mapWidth, 'JPEG');

                // ADDING PIE CHART CONTENT
                doc.text(pieChartTitle, 20, currentY);
                currentY += 5;

                // ADDING PIE CHART STATISTICS
                doc.setFontSize(12);
                doc.setTextColor(0);
                doc.setFont('helvetica', 'normal');

                // PIE CHART STATISTICS TABLE DATA
                const pieChartStatisticsTableData = pieChartStatistics.map((item) => [{
                    content: item.name,
                    styles: {
                        halign: 'center',
                        cellWidth: 40,
                        cellHeight: 10,
                        minCellHeight: 15.1,
                        valign: 'middle',
                        fontSize: 12,
                        fillColor: 'black',
                        textColor: 'white',
                    },
                }, {
                    content: item.value,
                    styles: {
                        halign: 'center',
                        cellWidth: 40,
                        cellHeight: 10,
                        minCellHeight: 15.1,
                        valign: 'middle',
                        fontSize: 12,
                        fillColor: 'black',
                        textColor: 'white',
                    },
                }]);

                // ADDING PIE CHART STATISTICS TABLE
                doc.autoTable({
                    startY: currentY,
                    margin: {
                        left: pieChartWidth + 35
                    },
                    body: pieChartStatisticsTableData,
                    bodyStyles: {
                        fontSize: 10,
                        font: 'times',
                        textColor: 0,
                        minCellHeight: 15.1,
                    },
                    columnStyles: {
                        0: { cellWidth: 40 },
                        1: { cellWidth: 40 },
                    },
                });

                // ADDING PIE CHART
                doc.addImage(pieChartContainer, 'JPEG', 20, currentY, pieChartWidth, pieChartHeight);

                // DEFINING TABLE DATA FOR LEGEND
                const tableData = legend.map((item) => [{
                    content: item.name,
                    styles: {
                        halign: 'left',
                        cellWidth: 32,
                        fillColor: 'black',
                        textColor: 'white',
                        cellHeight: 5,
                        valign: 'middle',
                    },
                }, {
                    content: '',
                    styles: {
                        fillColor: item.color,
                        halign: 'center',
                        cellWidth: 5,
                        cellHeight: 5,
                        valign: 'middle',
                    },
                }]);

                // ADDING LEGEND CONTENT
                doc.autoTable({
                    startY: 88,
                    margin: {
                        left: Math.ceil(mapWidth + 30)
                    },
                    body: tableData,
                    columnStyles: {
                        0: { cellWidth: 32 },
                        1: { cellWidth: 5 },
                    },
                });

                currentY += doc.previousAutoTable.finalY;

                // BREAKING PAGE
                doc.addPage();

                // RESETTING Y
                currentY = 20;

                doc.setFontSize(11);
                doc.setFont('times');
                doc.text(mapDescriptionText, 20, currentY);

                currentY += 30;
                doc.text(statsDescriptionText, 20, currentY);

                currentY += 15

                doc.setFontSize(16);
                doc.setTextColor(0, 157, 215)
                doc.setFont('times', 'bold');

                // ADDING CHART CONTENT
                doc.text(cropsChartTitle, 20, currentY);
                currentY += 5;
                addContent(cropsChartContainer, cropsChartHeight, cropsChartWidth, 'JPEG');

                currentY += 3;
                doc.setTextColor(0);
                doc.setFontSize(11);
                doc.setFont('times', 'normal');
                doc.text(cropCalendarDescriptionText, 20, currentY);

                // BREAKING PAGE
                doc.addPage();

                // RESETTING Y
                currentY = 20;

                doc.setFontSize(16);
                doc.setTextColor(0, 157, 215)
                doc.setFont('times', 'bold');

                // ADDING CROPS CHART CONTENT
                doc.text(chartTitle, 20, currentY);
                currentY += 10;

                doc.setFont('times', 'normal')
                doc.setTextColor(0);
                doc.setFontSize(11);
                doc.text(indicesDescriptionText, 20, currentY);

                currentY += 30
                addContent(chartContainer, chartHeight, chartWidth, 'JPEG');

                currentY += 10;

                doc.setFont('times', 'normal')
                doc.setTextColor(0);
                doc.setFontSize(11);
                doc.text(indexDescriptionText, 20, currentY);

                // ADDING CREDITS
                doc.setFillColor(255, 255, 255);
                doc.rect(Math.ceil(pageWidth / 2), pageHeight - 10, pageWidth / 2, 20, 'F');

                doc.setTextColor(0, 157, 215)
                doc.setFontSize(12);
                doc.setFont('courier','italic', 'normal');
                const creditsText = `Done by ${user?.name}`;
                doc.text(creditsText, pageWidth - 50, pageHeight - 3);

                const sketchLayer = view.map.findLayerById('sketchLayer');
                if (sketchLayer) {
                    sketchLayer.graphics.forEach((graphic) => {
                        if (graphic.geometry.type === 'polygon') {
                            addContent(
                                graphic.symbol.imageData,
                                mapHeight
                            );
                        }
                    });
                }

                // SAVING PDF
                doc.save(`${text}.pdf`);
            });
        }
    } catch (error) {
        console.log(`Error capturing and saving the screenshot: ${error}`);
    }
};

export default exportMap;