import { TrashIcon } from "@heroicons/react/24/outline";
import PropTypes from 'prop-types';

const SeasonsTable = ({ seasons, deleteSeason }) => {
  const handleDelete = ({ id }) => {
    deleteSeason(id);
  }
  
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <tbody>
        { typeof seasons === Object && seasons.map((season) => (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="flex flex-col px-6 py-4 gap-1x text-gray-900 whitespace-nowrap dark:text-white">
                <h4 className="font-semibold">{season.name}</h4>
              </td>
              <td className="px-6 py-4">
                {new Date(season.start_date).toDateString()}
              </td>
              <td className="px-6 py-4">
                {new Date(season.end_date).toDateString()}
              </td>
              <td className="px-6 py-4 text-right">
                <TrashIcon
                  className="w-4 h-4 hover:text-red-600"
                  role="button"
                  onClick={() => handleDelete(season)}
                />
              </td>
            </tr>
          )) }
      </tbody>
    </table>
  );
};

SeasonsTable.propTypes = {
  seasons: PropTypes.array.isRequired, 
  deleteSeason: PropTypes.func.isRequired
}

export default SeasonsTable;
