import React, { useEffect, useRef } from "react";
import SceneView from "@arcgis/core/views/SceneView.js";
import WebScene from "@arcgis/core/WebScene.js";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import LineSymbol from "@arcgis/core/symbols/LineSymbol.js";
import Polygon from "@arcgis/core/geometry/Polygon.js";
import Graphic from "@arcgis/core/Graphic.js";
import Point from "@arcgis/core/geometry/Point.js";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils.js";
import LayerList from "@arcgis/core/widgets/LayerList.js";
import Expand from "@arcgis/core/widgets/Expand";
import Fullscreen from "@arcgis/core/widgets/Fullscreen";
import esriConfig from "@arcgis/core/config.js";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setViewRef } from "../../../features/MapExport/ExportSlice";
import Legend from "@arcgis/core/widgets/Legend.js";

esriConfig.portalUrl = "https://portal.space.gov.rw/portal/";
esriConfig.applicationName = "RSA Smart Agriculture";

const MapComponent = React.memo(
  ({
    cursorStyle,
    coordinates,
    sketchingEnabled,
    pinPlaced,
    setCoordinates,
  }) => {
    const mapRef = useRef(null);
    const viewRef = useRef(null);
    const sketchRef = useRef(null);
    const polygonRef = useRef(null);
    const dispatch = useDispatch();

    const initializeMap = () => {
      try {
        const webScene = new WebScene({
          portalItem: {
            id: "29c54299ecc94a95ac0860b841e49d67"
          }
        });

        const view = new SceneView({
          container: mapRef.current,
          map: webScene,
          viewingMode: "local",
          zoom: 8,
          center: [30.0037, -1.8445],
        });

        const layerList = new LayerList({
          view: view,
        });

        const layerListExpand = new Expand({
          view: view,
          content: layerList,
        });

        // Add the LayerList widget to the view
        view.ui.add(layerListExpand, "top-left");

        const fullscreen = new Fullscreen({
          view: view,
        });

        // Add the Fullscreen widget to the view
        view.ui.add(fullscreen, "bottom-left");

        // Add legends of all the layers on the Map
        let legend = new Legend({
          view: view
        });
        
        view.ui.add(legend, "bottom-right");

        /**
         * This polygon is updated once coordinates changes
         * polygon rings are updated
         * Otherwise the outline boundaries won't show
         */
        const polygon = new Polygon({
          rings: [],
          spatialReference: view.spatialReference,
        });

        polygonRef.current = polygon;

        // Remove All other map components except zoom and compass
        view.ui.components = ["zoom", "compass"];
        viewRef.current = view;
        dispatch(setViewRef(view));

        return () => {
          if (viewRef.current) {
            viewRef.current.destroy();
            viewRef.current = null;
          }
        };
      } catch (error) {
        console.error(`Error while setting the map up ${error}`);
      }
    };
    initializeMap();

    useEffect(() => {
      try {
        if (coordinates === undefined) {
          console.log("No coordinates");
        } else {
          const view = viewRef.current;

          /**
           * Add AOI (Area of Interest) polygon
           */
          const polygon = polygonRef.current;
          polygon.rings = [coordinates]; // Update the polygon rings with new coordinates

          const graphic = new Graphic({
            geometry: polygon,
            symbol: {
              type: "polygon-3d",
              symbolLayers: [
                {
                  type: "fill",
                  material: {
                    color: [0, 0, 0, 0],
                  },
                  outline: {
                    color: [204, 102, 0],
                    size: 4,
                  },
                },
              ],
            },
          });

          view.graphics.add(graphic);
          view.extent = polygon.extent.expand(1.5);
        }
      } catch (error) {
        console.log(`adding map polygon error ${error}`);
      }
    }, [coordinates]);

    useEffect(() => {
      const addSketchToView = () => {
        try {
          if (sketchingEnabled && viewRef.current) {
            const view = viewRef.current;
            let layer = view.map.findLayerById("sketchLayer");
            if (!layer) {
              layer = new GraphicsLayer({ id: "sketchLayer" });
              view.map.add(layer);
            }

            const sketch = new Sketch({
              view: view,
              layer: layer,
              availableCreateTools: ["polygon"],
              availableUpdateTools: [],
              creationMode: "update",
              defaultUpdateOptions: {
                enableZ: false,
                enableRotation: false,
              },
              defaultCreateOptions: {
                hasZ: false,
              },
              lineSymbol: new LineSymbol({
                color: [204, 102, 0, 0.5],
                outline: {
                  color: [204, 102, 0, 1],
                  width: 2,
                },
              }),
            });

            sketchRef.current = sketch;
            view.ui.add(sketch, "top-right");

            view.on("click", (event) => {
              if (sketch.viewModel.view && sketchingEnabled && !pinPlaced) {
                sketch.create("point", { symbol: "pin" });
                // setPinPlaced(true);
              }
            });

            sketch.on("create", (event) => {
              if (
                event.state === "complete" &&
                event.graphic.geometry.type === "polygon"
              ) {
                const polyline = event.graphic.geometry;
                const polygonRings = polyline.rings[0].map((ring) => {
                  const point = new Point(ring);
                  const lonLat = webMercatorUtils.xyToLngLat(point.x, point.y);
                  return lonLat;
                });

                const polygon = polygonRef.current;

                polygon.rings = [polygonRings];
                setCoordinates(polygonRings);
              }
            });
          }
        } catch (error) {
          console.error(`Adding sketch to map error ${error}`);
        }
      };
      addSketchToView();

      return () => {
        if (sketchRef.current) {
          sketchRef.current.destroy();
          sketchRef.current = null;
        }
      };
    }, [pinPlaced, setCoordinates, sketchingEnabled]);

    return (
      <div
        ref={mapRef}
        className="relative h-auto col-span-full md:col-span-3"
        id="map"
        style={{ cursor: cursorStyle }}
      >
        {/* <div className="absolute p-4 bottom-1 right-2 bg-slate-900">
          <div className="mb-1 text-center">
            <span className="text-center text-red-600 uppercase text-md">
              Legend
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-green-500"></div>
              <div>
                <span className="text-xs text-white">Cropland</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-green-700"></div>
              <div>
                <span className="text-xs text-white">Forest</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-black"></div>
              <div>
                <span className="text-xs text-white">Settlement</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-cyan-500"></div>
              <div>
                <span className="text-xs text-white">Water Bodies</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-orange-400"></div>
              <div>
                <span className="text-xs text-white">
                  Non-vegetated cropland
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-orange-700"></div>
              <div>
                <span className="text-xs text-white">Grassland</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-cyan-300"></div>
              <div>
                <span className="text-xs text-white">Wetland</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
);

MapComponent.propTypes = {
  cursorStyle: PropTypes.string.isRequired,
  coordinates: PropTypes.array.isRequired,
  sketchingEnabled: PropTypes.bool.isRequired,
  setCoordinates: PropTypes.func.isRequired,
};

export default MapComponent;
