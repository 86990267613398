import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from '../../config/axios.config';

const initialState = {
    isLoading: false,
    data: null,
    progress: null,
    error: null,
    message: null
}

export const createCIPCrop = createAsyncThunk(
    'create-cip-crop',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('crops/create', payload);
            return res.data; 
        } catch (error) {
            console.error(`Error creating CIP crop: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while creating CIP Crop');
            }
        }
    }
)

export const getSeasonCIPCrops = createAsyncThunk(
    'get-cip-crops',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('crops/all', payload);
            return res?.data?.data;  
        } catch (error) {
            console.error(`Error getting CIP crops: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting all CIP Crops');
            }
        }
    }
)

export const getAllCIPCrops = createAsyncThunk(
    'get-cip-crops',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('crops/all', payload);
            return res?.data?.data;  
        } catch (error) {
            console.error(`Error getting CIP crops: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting all CIP Crops');
            }
        }
    }
)

export const getAllCIPCropsProgress = createAsyncThunk(
    'get-cip-crops-progress',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('crops/progress');
            return res?.data?.data;  
        } catch (error) {
            console.error(`Error getting CIP crops progress: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting all CIP Crops progress');
            }
        }
    }
)

export const deleteCIPCrop = createAsyncThunk(
    'delete-crop',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.delete(`crops/delete?id=${payload.id}`);
            return res?.data?.data;  
        } catch (error) {
            console.error(`Error getting deleting crop: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while deleting CIP Crop');
            }
        }
    }
)

const cropsSlice = createSlice({
    name: 'crops',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(createCIPCrop.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createCIPCrop.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.message = payload?.message;
            })
            .addCase(createCIPCrop.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            .addCase(getAllCIPCrops.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllCIPCrops.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
                state.message = payload?.message;
            })
            .addCase(getAllCIPCrops.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            .addCase(getAllCIPCropsProgress.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllCIPCropsProgress.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.progress = payload;
                state.message = payload?.message;
            })
            .addCase(getAllCIPCropsProgress.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            .addCase(deleteCIPCrop.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteCIPCrop.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.message = payload?.message;
            })
            .addCase(deleteCIPCrop.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
    }
})

export default cropsSlice.reducer;