import { createSlice } from "@reduxjs/toolkit";

export const mapSlice = createSlice({
    name: 'map',
    initialState: {
        viewRef: null,
        chartContainer: null,
        cropsChartContainer: null,
        pieChartContainer: null,
        selectedIndex: null,
    },
    reducers: {
        setViewRef: (state, { payload }) => {
            state.viewRef = payload;
        },
        setChartContainer: (state, {payload}) => {
            state.chartContainer = payload
        },
        setCropsChartContainer: (state, {payload}) => {
            state.cropsChartContainer = payload
        },
        setPieChartContainer: (state, {payload}) => {
            state.pieChartContainer = payload
        },
        setIndexValue: (state, {payload}) => {
            state.selectedIndex = payload
        },
    }
});

export default mapSlice.reducer;

export const { setViewRef, setChartContainer, setCropsChartContainer, setPieChartContainer, setIndexValue } = mapSlice.actions;
