import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from '../../config/axios.config';

const initialState = {
    isLoading: false,
    data: null,
    error: null
}

export const getAdministrativeBoundary = createAsyncThunk(
    'get-administrative-boundary',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('administrative-boundaries', payload);
            return res?.data?.data; 
        } catch (error) {
            console.error(`Error getting crop land data: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting crop land data');
            }
        }
    }
);

export const getUPIBoundaries = createAsyncThunk(
    'get-upi-boundary-coordinates',
    async(payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('upi-boundaries', payload);
            return res?.data?.data; 
        } catch (error) {
            console.error(`Error getting crop land data: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while getting crop land data');
            }
        }
    }
)

const boundariesSlice = createSlice({
    name: 'boundaries',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdministrativeBoundary.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAdministrativeBoundary.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
            })
            .addCase(getAdministrativeBoundary.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            .addCase(getUPIBoundaries.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUPIBoundaries.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
            })
            .addCase(getUPIBoundaries.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
    }
});

export default boundariesSlice.reducer;