import React, {useEffect, useRef, useState} from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { setPieChartContainer } from '../../../../features/MapExport/ExportSlice';
import {useDispatch} from 'react-redux';

const PieChart = ({ chartData }) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const [ticking, setTicking] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ticking && count < 20) {
        if(chartData) {
          const chartContainer = chartRef.current;
          html2canvas(chartContainer).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            dispatch(setPieChartContainer(imgData));
          })
          setCount(prevState => prevState +1);
        }
      }
    }, 2e3)
    return () => clearTimeout(timer)
  }, [count, ticking]);



  return (
      <div className='bg-slate-900 p-2' ref={chartRef}>
        <div className="w-32 flex items-center justify-center chart-container h-40 sm:h-40 md:h-32 lg:h-24 2xl:h-48">
          <Doughnut
            data={{
              labels: chartData.labels,
              datasets: [
                {
                  data: chartData.data,
                  backgroundColor: [
                    "#15803D",
                    "#3730A3"
                  ]
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: false,
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
  );
};

PieChart.propTypes = {
  chartData: PropTypes.object.isRequired,
}

export default PieChart;
