import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import './App.css';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Reports from './pages/reports/Reports';
import Settings from './pages/settings/Settings';
import AuthProvider from './providers/authProvider';
import {ProtectedRoute} from './components/ProtectedRoute';
import ForgetPassword from './pages/forget-password/ForgetPassword';

function App() {
  const publicRoutes = [
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/forget-password',
      element: <ForgetPassword />
    },
  ]
  const protectedRoutes = [
    {
      path: "/",
        element: <ProtectedRoute />,
        children: 
        [
          {
            path: '/',
            element: <Home />
          },
          {
            path : '/reports',
            element : <Reports />
          },
          {
            path : '/settings',
            element: <Settings />
          }
      ]
    }
  ]
  const adminRoutes = [
    {
      path: '/admin',
      element: <ProtectedRoute forRole='admin' />,
      children: [
        {
          // Routes that requires admin role
        }
      ]
    }
]

  const router = createBrowserRouter([
    ...publicRoutes,
    ...protectedRoutes,
    ...adminRoutes
  ]);

  return (
    <main className='h-full'>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </main>
  );
}

export default App;
