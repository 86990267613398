import { TrashIcon } from "@heroicons/react/24/outline";
import PropTypes from 'prop-types';

const UsersTable = () => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <tbody>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
            <td className="flex flex-col px-6 py-4 gap-1x text-gray-900 whitespace-nowrap dark:text-white">
              <h4 className="font-semibold">Sample User 1</h4>
              <p className="text-gray-400">testuseremail@user.com</p>
            </td>
            <td className="px-6 py-4">Admin</td>
            <td className="px-6 py-4 text-right">
                <TrashIcon className="w-4 h-4 hover:text-red-600" role="button" />
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="flex flex-col px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
              <h4 className="font-semibold">Sample User 2</h4>
              <p className="text-gray-400">testuseremail@user.com</p>
            </td>
            <td className="px-6 py-4">Admin</td>
            <td className="px-6 py-4 text-right">
              <TrashIcon className="w-4 h-4 hover:text-red-600" role="button" />
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="flex flex-col px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
              <h4 className="font-semibold">Sample User 3</h4>
              <p className="text-gray-400">testuseremail@user.com</p>
            </td>
            <td className="px-6 py-4">Admin</td>
            <td className="px-6 py-4 text-right">
              <TrashIcon className="w-4 h-4 hover:text-red-600" role="button" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

UsersTable.propTypes = {
  users: PropTypes.array, 
  deleteUser: PropTypes.func
}

export default UsersTable;
