import { isWithinInterval } from "date-fns";

/**
 * 
 * @param {* Dates array} dates 
 * @param {* Low end date} startDate 
 * @param {* High end date} endDate 
 * @returns An array of dates between the two dates
 */
export const filterDates = (dates = [], startDate = new Date(), endDate = new Date()) => {
    return dates.filter((singleDate) => {
        return isWithinInterval(new Date(singleDate), {
            start: new Date(startDate),
            end: new Date(endDate)
        });
    })
}