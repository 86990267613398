import {useCallback, useEffect, useRef, useState} from "react";
import { Carousel } from "react-responsive-carousel";
import DateCarousel from "./SatelliteDatesCarousel";
import { PropagateLoader, PuffLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { getSeasonCIPCrops, getAllCIPCropsProgress } from "../../../features/crops/cropsSlice";
import { getCurrentAgriculturalSeason } from "../../../features/seasons/agriculturalSeasonSlice";
import { getSatelliteDates } from '../../../features/satellite-dates/satelliteDatesSlice';
import format from "date-fns/format";
import isSameWeek from "date-fns/isSameWeek";
import PropTypes from 'prop-types';
import { getCropHealthData } from "../../../features/crop-health/cropHealthSlice";
import { filterDates } from "../../shared/helpers";
import html2canvas from "html2canvas";
import {setCropsChartContainer, setIndexValue} from "../../../features/MapExport/ExportSlice";
import CropsCalendar from "./charts/CropsCalendarBarChart";
import CropsHealthChart from "./charts/CropsHealthChart";

const dummyData = {
  labels: [],
  data: [
    [],
    [],
    [],
    [],
  ],
};

const CropGraphCarousel = ({ coordinates }) => {
  const dispatch = useDispatch();
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [index, setIndex] = useState('');
  const [errors, setErrors] = useState({});
  
  const satelliteDates = useSelector(state => state.satelliteDates.data);
  // const cropsData = useSelector((state) => state.crops.data ?? []);
  const cropsProgress = useSelector((state) => state.crops.progress ?? []);
  const currentSeason = useSelector((state) => state.seasons.data ?? null);
  const cropHealthData = useSelector((state) => state.cropHealth.data ?? null);
  const isLoadingCropHealthData = useSelector((state) => state.cropHealth.isLoading ?? null);
  const [chartData, setChartData] = useState(dummyData);

  const chartRef = useRef(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count < 20) {
        if(chartData) {
          const chartContainer = chartRef.current;
          html2canvas(chartContainer).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            dispatch(setCropsChartContainer(imgData))
          })
        }

      }
      setCount(prevState => prevState +1);
    }, 2e3)
    return () => clearTimeout(timer)
  }, [count]);

  useEffect(() => {
    if (index) {
      dispatch(setIndexValue(index));
    }
  }, [index]);

  useEffect(() => {
    if (currentSeason != null && currentSeason !== undefined) {
      const season = currentSeason.name;
      dispatch(getSeasonCIPCrops(season))
    }
  }, [currentSeason, dispatch])

  useEffect(() => {
    dispatch(getCurrentAgriculturalSeason())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllCIPCropsProgress())
  }, [dispatch])

  useEffect(() => {
    if (!satelliteDates) {
      dispatch(getSatelliteDates())
    }
  }, [dispatch, satelliteDates])

  useEffect(() => {
    if (cropHealthData !== null) {
      setChartData({
        labels: filterDates(satelliteDates, start_date, end_date),  // Get satellites date between two dates selected
        data: cropHealthData,
        label: index
      });
    }
  }, [cropHealthData, end_date, index, satelliteDates, start_date]);

  /**
   * Callback method for querying crop health data (indices)
   */
  const handleFetchCropHealth = useCallback(async (event) => {
    event.preventDefault();
    const cropHealthPayload = {
      "aoi": coordinates,
      "start_date": start_date,
      "end_date": end_date,
      "indix": index
    };
    try {
      dispatch(getCropHealthData(cropHealthPayload));
    } catch (error) {
      setErrors(error);
    }
  }, [coordinates, dispatch, end_date, index, start_date])

  function getSeasonDates(startDate, endDate) {
    const dates = [];
    const currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 7); // to make sure we display one date per week
    }
    return dates;
  }

  const seasonDates = currentSeason ? getSeasonDates(new Date(currentSeason.start_date), new Date(currentSeason.end_date)): [];

  return (
    <Carousel showThumbs={false} renderIndicator={false}>
      <div ref={chartRef} className="flex flex-col py-2 2xl:gap-4 pr-6 mt-1 text-white bg-gray-900 align-items-center chart-container 2xl:py-6">
        <div className="p-1 mb-4 lg:mb-2 bg-slate-800 2xl:py-4">
          {currentSeason && <p className="text-sm 2xl:text-lg 2xl:mb-3">Season {currentSeason.name}</p>}
          <div className="lg:flex md:flex justify-between hidden bg-slate-900">
            {seasonDates.map(date => (
              isSameWeek(new Date(date), new Date()) ? // Check if today date is the same week as one of the season weeks
              <span className="px-1 py-3 text-xs text-red-500 font-extrabold" key={date}>
                {format(new Date(date), 'MMM-dd-yy')}
              </span>
              :
              <span key={date} className="px-1 py-3 text-xs">{format(new Date(date), 'MMM dd,yy')}</span>
            ))}
          </div>
        </div>
        <div className="2xl:py-2">
          <p className="text-sm 2xl:text-xl">CIP Crops Calendar</p>
        </div>
        <div className="bg-slate-800 2xl:py-6">
          <CropsCalendar chartData={cropsProgress} />
        </div>
      </div>
      <div>
        <div className="py-1 mb-4 mt-8 lg:mb-1 2xl:pb-12 lg:mt-1 md:py-4">
          {satelliteDates ? (
            <DateCarousel datesData={satelliteDates} />
          ) : (
            <PropagateLoader
              className="items-center justify-center px-16 py-4"
              color="#52bfd9"
              size={15}
            />
          )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-6 md:grid-cols-6 gap-2 pl-6 mb-1">
          <div className="col-span-1 text-start">
            <form>
              <div className="mt-1 2xl:mt-2 flex flex-col 2xl:gap-2 py-1 rounded-md bg-slate-800">
                <label htmlFor="index" className="px-3 text-xs text-white text">
                  Index
                </label>
                <select
                  id="index"
                  onChange={(e) => setIndex(e.target.value)}
                  className="block w-full rounded-md border-0 py-0.5 2xl:py-2.5 text-white text-xs bg-slate-800"
                >
                    <option value="ndmi">NDMI</option>
                    <option value="ndvi">NDVI</option>
                    <option value="reci">RECI</option>
                    <option value="evi">EVI</option>
                    <option value="ndre">NDRE</option>
                    <option value="msavi">MSAVI</option>
                </select>
              </div>
              <div className="mt-1 2xl:mt-2 flex flex-col 2xl:gap-2 py-1 rounded-md bg-slate-800">
                <label
                  htmlFor="start_date"
                  className="px-3 text-xs text-white text"
                >
                  Start date
                </label>
                <input
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                  min={satelliteDates && satelliteDates[0]}
                  max={satelliteDates && satelliteDates[satelliteDates.length -1]}
                  className="w-full py-0 text-xs 2xl:py-2.5 text-white border-none rounded-lg bg-slate-800"
                />
              </div>
              <div className="mt-1 2xl:mt-2 flex flex-col 2xl:gap-2 py-1 rounded-md bg-slate-800">
                <label
                  htmlFor="end_date"
                  className="px-3 mb-0 text-xs text-white text"
                >
                  End date
                </label>
                <input
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                  min={start_date}
                  max={satelliteDates && satelliteDates[satelliteDates.length -1]}
                  className="w-full 2xl:py-2.5 py-0 text-xs text-white border-none rounded-lg bg-slate-800"
                />
              </div>
              <div className="mt-1 2xl:mt-2 flex flex-col 2xl:gap-2 py-1 rounded-md bg-slate-800">
                <label htmlFor="index" className="px-3 text-xs text-white text">
                  Weather Data
                </label>
                <select
                  id="index"
                  className="block w-full rounded-md border-0 py-0.5 2xl:py-2.5 text-white text-xs bg-slate-800"
                >
                  <option>Precipitation</option>
                  <option>Moisture</option>
                  <option>Temperature</option>
                </select>
              </div>
              <div className="mt-3 2xl:mt-5 flex flex-col 2xl:gap-2 py-1 text-white">
                <button className="py-2 2xl:py-4 lg:text-lg bg-blue-700 rounded-md" onClick={handleFetchCropHealth}>Get Data</button>
              </div>
            </form>
          </div>
          <div className="col-span-5">
            <div className="py-1">
              {isLoadingCropHealthData ? (
                <div className="flex flex-1 items-center justify-center py-16">
                  <PuffLoader
                    color="#52bfd9"
                    size={100}
                  />
                </div>
              ) : (
                <CropsHealthChart chartData={chartData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

CropGraphCarousel.propTypes = {
  coordinates: PropTypes.array.isRequired,
}

export default CropGraphCarousel;
