import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from '../../config/axios.config';

const initialState = {
    isLoading: false,
    data: null,
    error: null
}

export const getSatelliteDates = createAsyncThunk(
    'get-satellite-dates',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get("/satellite-dates");
            return res?.data?.data;
        } catch (error) {
            console.error(`Error getting satellites dates: ${error}`);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during getting satellite dates');
            } 
        }
    }
)

const dateSlice = createSlice({
    name: 'satellite-dates',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getSatelliteDates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSatelliteDates.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload;
            })
            .addCase(getSatelliteDates.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
    }
});

export default dateSlice.reducer;