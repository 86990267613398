import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../config/axios.config';

const initialState = {
    isLoading: false,
    token: null,
    error: null,
    message: null,
    isAuthenticated: false,
    user: {},
}

/**
 * Auth actions
*/

export const signIn = createAsyncThunk(
    'login',
    async (credentials, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('/auth/login', credentials);
            if (!res || !res.data || res.status !== 200) {
                throw rejectWithValue('Invalid Username or password provided!');
            }
            return res.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw rejectWithValue(error.response.data.message);
            } else {
                console.log(error);
                throw rejectWithValue('An error occurred during login.');
            }
        }
    }
);

export const forgetPassword = createAsyncThunk(
    'forget-password',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('/auth/forget/password', payload);
            return res.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                console.log(error);
                return rejectWithValue('An error occurred during login.');
            }
        }
    }
);

export const registerUser = createAsyncThunk(
    'register',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post("/auth/register", payload);
            return res.data;
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during creating user');
            }
        }
    }
);

// ------------------End of auth actions----------------

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(signIn.fulfilled, (state, { payload }) => {
                const { user, access_token, refresh_token } = payload.data
                state.user = user;
                state.token = access_token;
                state.isLoading = false;
                state.isAuthenticated = true;
                localStorage.setItem('token', access_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('user', JSON.stringify(user));
            })
            .addCase(signIn.rejected, (state, { error }) => {
                state.error = error.message || 'Login failed';
                state.isLoading = false;
                state.isAuthenticated = false
            })
            .addCase(forgetPassword.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(forgetPassword.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.message = payload?.message
            })
            .addCase(forgetPassword.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message || 'resetting password failed';
            })
            .addCase(registerUser.pending, (state) => {
                state.isLoading = false;
            })
            .addCase(registerUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.message = payload?.message;
            })
            .addCase(registerUser.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            });
    },
});

export default authSlice.reducer;