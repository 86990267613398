import React from 'react'

const AuthLayout = ({children}) => {
  return (
    <div className='h-full bg-white'>
        {children}
    </div>
  )
}

export default AuthLayout