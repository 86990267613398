import { RingLoader } from 'react-spinners';
import land_image from '../../../assets/images/backgrounds/sat_image_land.jpg';
import './splash.css'
const HomeSplashScreen = () => {
    return (
        <div className='relative h-screen w-full'>
            <img src={land_image} alt='Land Cover by Satellite' className='w-full h-full splash-image' />
            <div className='absolute inset-0 flex items-center justify-center bg-blue-500 bg-opacity-25'>
                <h1 className='text-5xl font-extrabold text-white uppercase'>Getting ready...</h1>
                <RingLoader
                    className="items-center justify-center px-16 py-4"
                    color="#fff"
                    size={100}
                />
            </div>
        </div>
    )
}

export default HomeSplashScreen;