import React, { Fragment } from 'react'
import { Bars3Icon, ChevronUpDownIcon, UserIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'

const Sidebar = ({ navigation, setSidebarOpen, classNames }) => {
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        sessionStorage.removeItem('hasBeenLaunched'); // Renders splash screen on next login and set it again

        window.location.href = '/login';
    }

    return (
        <>
            < div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-52 2xl:w-80 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                < div className="flex flex-col px-6 overflow-y-auto border-r border-gray-200 grow gap-y-5" >
                    <div className="flex items-center h-16 shrink-0">
                        <h2 className='text-lg font-bold leading-9 tracking-tight text-indigo-600'>Smart Agriculture</h2>
                    </div>
                    <Menu className="flex flex-col flex-1">
                        <ul className="flex flex-col flex-1 gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">
                                    {navigation.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-gray-500 text-indigo-600 text-white'
                                                        : 'text-gray-400 hover:text-indigo-600 hover:bg-gray-50',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current ? 'text-white' : 'text-white group-hover:text-indigo-600',
                                                        'h-6 w-6 shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="mt-auto -mx-6 bg-gray-600">
                                <Menu.Button className="flex items-center px-6 py-3 text-sm font-semibold leading-6 text-white gap-x-4 hover:bg-gray-50 hover:text-black">
                                    <UserIcon className='w-6 h-6' />
                                    <span className="sr-only">Your profile</span>
                                    <span aria-hidden="true">{localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).name : ''}</span>
                                    <span>
                                        <ChevronUpDownIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Menu.Button>
                            </li>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-2 bottom-14 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className='py-1'>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <li className='text-gray-700 block px-4 py-2 text-sm hover:cursor-pointer' onClick={handleLogout} >
                                                    Sign out
                                                </li>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </ul>
                    </Menu>
                </div >
            </div >

            <div className="sticky top-0 z-40 flex items-center px-4 py-4 bg-white shadow-sm gap-x-6 sm:px-6 lg:hidden">
                <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                </button>
                <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Home</div>
                <Link to="#">
                    <span className="sr-only">Your profile</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role='button' fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                    </svg>
                </Link>
            </div>
        </>
    )
}

export default Sidebar