import React, { useCallback, useState } from 'react'
import Turnstone from 'turnstone';
import PropTypes from 'prop-types';
import instance from '../../../config/axios.config';

const styles = {
    input: 'border border-gray-300 rounded-lg pl-4 pr-4 py-0.5 w-full text-sm 2xl:py-1.5',
    inputFocus: 'border border-gray-300 rounded-lg pl-4 pr-4 py-0.5 w-full text-sm',
    query: 'text-slate-800 placeholder-slate-400',
    typeahead: 'text-blue-900 border-white',
    cancelButton: `absolute w-10 h-12 inset-y-0 left-0 items-center justify-center z-10 text-blue-400 inline-flex sm:hidden`,
    clearButton: 'absolute inset-y-0 right-0 w-8 inline-flex items-center justify-center text-slate-400 hover:text-rose-400',
    listbox: 'w-full bg-white sm:border sm:border-blue-300 rounded-md text-left sm:mt-2 p-1 sm:drop-shadow-xl h-80 overflow-auto',
    groupHeading: 'cursor-default mt-2 mb-0.5 px-1.5 uppercase text-md text-black font-bold',
    item: 'cursor-pointer p-0 text-sm overflow-ellipsis overflow-hidden text-indigo-600',
    noItems: 'cursor-default text-center my-20 text-light-blue-500',
    highlightedItem: 'cursor-pointer p-1 text-md overflow-ellipsis overflow-hidden text-white rounded bg-slate-800'
}

const maxItems = 60;

const Item = ({ item }) => {
    return (
      <div className='flex flex-col gap-0.5 cursor-pointer px-5 py-4 text-xs'>
        {item && item.province && <p>Province: {item.province}</p>}
        {item && item.district && <p>District: {item.district}</p>}
        {item && item.sector && <p>Sector: {item.sector}</p>}
        {item && item.cell && <p>Cell: {item.cell}</p>}
        {item && item.village && <p>Village: {item.village}</p>}
      </div>
    )
}
const listbox = {
    displayField: 'name',
    ratio: 2,
    data: async (query) => {
        const res = await instance.post(`/search-administrative-boundaries`, {
            name: query
        });
        return res.data.data
    },
    searchType: 'startsWith'
}

function SearchBox({ onSearch }) {
    const [selectedItem, setSelectedItem] = useState(null);

    /**
     * FUnction to handle search once search suggestion is clicked
     */
    const onSelect = useCallback((item) => {
        setSelectedItem(item);
        if (selectedItem != null) {
            onSearch({
                data: selectedItem,
                type: 'admin_boundary'
            });
        }
    }, [onSearch, selectedItem]);

    /**
     * FUnction to handle search once Tab key is pressed
     */
    const onTab = useCallback(
        (selectedResult) => {
            if (selectedResult != null) {
                onSearch({
                    data: selectedResult,
                    type: 'upi'
                })
            }
        }, [onSearch]
    );

    /**
     * FUnction to handle search once Enter Key is pressed
     */
    const onEnter = useCallback(
        (selectedResult) => {
            if (selectedResult != null) {
                onSearch({
                    data: selectedResult,
                    type: 'upi'
                })
            }
        }, [onSearch]
    );

    return (
        <Turnstone
        cancelButton={true}
        clearButton={true}
        debounceWait={500}
        id="search"
        listbox={listbox}
        listboxIsImmutable={true}
        matchText={false}
        autoCapitalize={true}
        maxItems={maxItems}
        name="search"
        onSelect={onSelect}
        onTab={onTab}
        onEnter={onEnter}
        noItemsMessage="No place found matching your search. Searching for UPI!"
        placeholder="Enter place name or UPI"
        styles={styles}
        Item={Item}
        typeahead={true} />
    )
}

SearchBox.propTypes = {
    onSearch: PropTypes.func.isRequired,
}

export default SearchBox;